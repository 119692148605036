$dark-green: #54747a;
$white: #fff;
$green: #47979d;
$black: #222;
$light: #fafafa;
$gray: #f2f2f2;
$gray-1: #fafafa;
$gray-2: #f3f3f3;
$gray-3: #f2f2f2;
$gray-4: #8c8c8c;
$gray-5: #828282;
$gray-6: #b3b3b3;
$gray-7: #bdbdbd;
$border-color: #cdd5db;

:root {
  --background: #f8f8f8;
  --green: #47979d;
  --dark-green: #54747a;
  --white: #fff;
  --black: #222;
  --light: #fafafa;
  --violet: #7e57c2;
  --blue: #29b6f6;
  --red: #ef5350;
  --yellow: #ffca28;
  --gray: #f2f2f2;
  --gray-2: #e0e0e0;
  --gray-3: #bdbdbd;
  --gray-4: #828282;
}
