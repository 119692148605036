@import "vars";

@media (min-width: 768px) {
  .ceils-content-wrapper {
    padding: 20px 20px 18px 20px;
    border: 1px solid $gray-2;
    border-radius: 0px 2px 2px 2px;
    min-height: 60vh;


    &.competitions-wrap {
      border: 0;
      padding-top: 1px;
      padding-left: 1px;

      .ceils-content-section{
        margin-right: 0;

        .ceil-competition-item{
          margin-top: -1px;
          margin-left: -1px;
        }
      }

      .page-pagination {
        margin-right: 20px;
      }
    }

    .foot-text {
      padding: 15px 0;
    }
  }
  .ceils-content-header {
    .job-search {
      width: 300px;
      margin: 0;
    }

  }

  .ceils-content-section {
    border: none;
    margin-left: 0;
    margin-right: -10px;

    &.justify-content-md-between{
      margin-right: 0;
      padding-left: 1px;
    }

    .articles-list{
      margin-right: 10px;
    }
  }

  .ceil-item {
    flex-direction: column;
    border: 1px solid $gray-2;
    border-radius: 2px;
    margin: 0 10px 10px 0;
    padding: 0;

    &:nth-child(4n) {
      margin-right: 0;
    }


    .ceil-logo-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      background: $light;
      height: 60px;

      .ava, .ceil-logo {
        width: 80px;
        height: 80px;
        font-size: 18px;
        position: absolute;
        top: 9px;
        border: 2px solid var(--gray-2);
        --background: #eeeeee;
      }
    }

    .ceil-content {
      padding: 38px 20px 20px;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      max-height: 158px;
      overflow: hidden;
    }

    .ceil-text {
      overflow: hidden;
      //height: 80px;
      text-align: center;
      margin-bottom: 6px;
    }

    .ceil-description {
      text-align: center;
      margin: 2px 0 0 0;
    }

    .ceil-explore-link {
      text-align: center;
      margin-top: auto;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: $green;
    }
  }

  .ceil-article-item {
    width: 49%;
    border: 1px solid $gray-2;
    border-radius: 2px;
    margin: 0 0 2% 0;
    height: 115px;
    display: flex;
    flex-direction: column;

    .ceil-description{
      max-height: 30px;
      overflow: hidden;
    }

    &.submission-item{
      .ceil-description{
        max-height: unset;
        overflow: hidden;
      }
    }

    &.network-item{
      height: auto;
    }

    &.group-event-item{
      padding: 20px 10px;
      height: 110px;

      .icon{
        width: 70px;
        height: 70px;
        margin-right: 18px;

        svg{
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .page-pagination {
    margin: 20px 20px 20px 0;

    &-wrapper{
      border: 1px solid $gray-2;
      border-radius: 0 0 2px 2px;
      margin-top: -1px;
    }
  }
}

@media (min-width: 992px) {
  header {
    height: 60px;
    margin-bottom: 30px;

    .text-between-logos {
      font-size: 14px;
      line-height: 16px;
    }

    .colleges-logo {
      width: 40px;
      height: 40px;
    }

    .rp-logo {
      width: 28px;
      height: 20px;
    }

    form {
      input,
      .btn {
        height: 40px;
        padding: 10px;
        margin-left: 12px;
      }

      .btn {
        width: 120px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .search-block {
    &-title {
      font-size: 22px;
      line-height: 26px;
    }

    form {
      button {
        width: 150px;
        height: 50px;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1164px;
  }

  .ceil-item {
    margin: 0 10px 10px 0;
    &:nth-child(4n) {
      margin-right: 10px;
    }
    &:nth-child(5n) {
      margin-right: 0;
    }
  }

  .ceil-article-item {
    width: 544px;
    margin: 0 0 10px 0;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1164px;
  }
}
