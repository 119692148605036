@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "vars";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

svg{
  vertical-align: top;
}

a{
  text-decoration: none;
}

* {
  outline: none;
}

.rp-dialog {
  width: 380px;
}

.btn {
  background: $green;
  color: $white;
  text-align: center;
  font-weight: bold;
  border: none;
  border-radius: 6px;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    color: $white;
  }
}

input {
  border-radius: 6px;
  background: $gray-1;
}

body {
  background: $gray-3;
  font-family: "Roboto", sans-serif;
  color: $black;
}

.block {
  background: $white;
  border-radius: 10px;
}

header {
  background: $dark-green;
  //height: 54px;
  height: 60px;
  //padding: 12px 0;
  display: flex;
  align-items: center;
  //margin-bottom: 10px;
  margin-bottom: 30px;

  .text-between-logos {
    color: $white;
    margin: 0 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  .colleges-logo {
    width: 30px;
    height: 30px;
  }

  .rp-logo {
    width: 22px;
    height: 16px;
  }

  form {
    input,
    .btn {
      height: 30px;
      padding: 8px;
      margin-left: 10px;
    }

    input {
      width: 230px;
      font-size: 11px;
      line-height: 13px;
      border: 1px solid #f2f2f2;
    }

    .btn {
      width: 70px;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .menu {
    &-item {
      padding: 8px 10px;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:hover {
        & > .profile-menu {
          display: block;
        }
      }

      &.active {
        background: $green;
      }

      .icon {
        margin-bottom: 7px;
        width: 24px;
        height: 24px;

        svg {
          width: 22px;
          height: 22px;
          vertical-align: unset;
        }
      }

      .avatar {
        margin-bottom: 7px;
        width: 24px;
        height: 24px;
        overflow: hidden;
        border-radius: 50%;
        text-decoration: none;

        svg {
          width: 22px;
          height: 22px;
          vertical-align: unset;
        }

        img {
          width: 24px;
          height: 24px;
          vertical-align: unset;
        }
      }

      .title {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        color: $white;
      }
    }
  }

  .profile-menu {
    //width: 140px;
    width: 240px;
    display: none;
    background: $gray-1;
    //background: $white;
    border-radius: 2px;
    letter-spacing: 0.01em;
    cursor: default;
    position: absolute;
    z-index: 2;
    bottom: 8px;
    transform: translateY(100%);
    right: 0;
    border: 1px solid #e0e0e0;

    &-item {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #222;
      text-decoration: none;
      padding: 10px;
      cursor: pointer;
      display: block;

      & + .profile-menu-item {
        border-top: 1px solid $gray-3;
      }

      &:last-of-type {
        margin-bottom: 5px;
      }
    }

    .user {
      padding: 10px;
      background: $gray-3;
      margin-bottom: 5px;

      .avatar {
        width: 46px;
        height: 46px;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: $black;
        margin-bottom: 2px;
      }

      .position {
        font-size: 13px;
        line-height: 15px;
        font-weight: 400;
        color: $gray-5;
      }
    }
  }
}

.search-block {
  margin-bottom: 20px;
  padding: 30px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("./assets/img/pencil-case.png") center center;
    opacity: 0.4;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &-title {
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 25px;
    font-weight: bold;

    svg {
      width: 32px;
      height: 32px;
      margin: 0 10px;
    }
  }

  form {
    width: 100%;
    z-index: 1;
    position: relative;

    .input-wrap {
      width: 100%;
      max-width: 340px;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 14px;
      }
    }

    // input{
    //   border: 2px solid $gray-2;
    //   width: 100%;
    //   height: 50px;
    //   font-size: 16px;
    //   line-height: 19px;
    //   letter-spacing: 0.01em;
    //   padding: 15px 20px;
    // }

    .input-hint {
      margin-top: 5px;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: $gray-4;
    }

    button {
      width: 140px;
      height: 44px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;
    }
  }
}

.college {
  &-wrap {
    margin: 0 -4px 40px;
  }

  &-item {
    margin: 0 6px 24px;
    position: relative;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 275px;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;

    &-more {
      align-items: center;
      justify-content: center;
      text-align: center;
      background: $white url("./assets/img/Rectangle.png") center center
        no-repeat;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      min-height: 300px;

      .btn {
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        width: 110px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-logo {
    margin: -30px auto 1rem;
    overflow: hidden;
    width: 90px;
    height: 90px;
    border-radius: 50%;

    img {
      display: block;
      margin: auto;
    }
  }

  &-favorite {
    &-wrap {
      position: absolute;
      cursor: pointer;
      width: 32px;
      height: 32px;
      top: 14px;
      right: 14px;
      overflow: hidden;
    }
  }

  &-title {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  &-info {
    margin-top: 14px;

    &-item {
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      align-items: center;
      text-transform: capitalize;

      &-icon {
        margin: 0 0.125rem;
        opacity: 0.5;

        &.selected-icon {
          opacity: 1;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }

      b {
        span {
          &.icon {
            svg {
              width: 13px;
              height: 13px;
              cursor: pointer;
              margin: 0 2px;
            }
          }
        }
      }

      .no-data {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: $gray-6;
        margin-left: auto;
      }
    }
  }

  &-type {
    &-title {
      font-size: 14px;
      color: black;
    }

    color: $dark-green;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  &-link {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $green;
    text-decoration: none;
    display: block;
    margin-top: 18px;
  }
}

.no-bookmarks-block {
  padding: 75px 0;

  svg {
    width: 100px;
    height: 100px;
    margin-bottom: 25px;
  }

  .text {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $black;
  }
}

body {
  .cdk-overlay-pane {
    max-width: calc(100vw - 20px) !important;
  }

  .mat-dialog-container {
    border-radius: 10px;
    padding: 30px 40px;
  }
}

.login-popup {
  font-size: 12px;
  line-height: 26px;

  &-logo {
    width: 44px;
    height: 30px;
    margin: 0 auto 25px;
    display: block;
  }

  &-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: $black;
    margin-bottom: 24px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    background: $gray-1;
    border: 1px solid $gray-3;
    border-radius: 6px;
    font-size: 12px;
    line-height: 14px;
    padding: 12px 10px;
    margin-bottom: 15px;
  }

  label {
    font-size: 11px;
    line-height: 26px;
    color: $black;
    display: flex;
    position: relative;
    cursor: pointer;

    input[type="checkbox"] {
      visibility: hidden;
      width: 0;
      height: 0;

      & + span {
        padding-left: 25px;

        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          transition: all 0.3s;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:before {
          background: $white;
          border: 1px solid $border-color;
          border-radius: 5px;
        }

        &:after {
          background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxMSA5Ij48cGF0aCBmaWxsPSIjNDc5NzlEIiBkPSJNMTAuNi40NWEuNi42IDAgMDEwIC44MkwzLjk2IDguMTggMS4xMyA1LjI0QS42LjYgMCAwMTIgNC40MWwxLjk3IDIuMDVMOS43NC40NWEuNi42IDAgMDEuODYgMHoiLz48L3N2Zz4=")
            center no-repeat;
          background-size: 66%;
          opacity: 0;
        }
      }

      &:checked {
        & + span {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  a {
    color: $green;
    font-size: 11px;
    line-height: 26px;
    text-decoration: none;
  }

  form {
    .btn {
      height: 44px;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;

      &.alt {
        background: $white;
        color: $black;
        border: 1px solid $border-color;
      }

      svg {
        width: 14px;
        height: 14px;
        margin: 0 3px;
      }
    }
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25rem 0;
  border-top-width: 0.5rem;
}

.job-search {
  display: flex;
  background: $light;
  border: 1px solid $gray-2;
  border-radius: 6px;
  height: 46px;
  align-items: center;
  padding: 0 14.5px 0 20px;

  input {
    display: block;
    width: 100%;
    border: none;
    background: none;
    padding: 0;
    height: 15px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    outline: 0;
  }

  button {
    display: block;
    width: 17.5px;
    height: 17.5px;
    margin: 0 0 0 10px;
    border: 0;
    padding: 0;
    background: transparent;
    outline: none;

    svg {
      fill: $gray-7;
    }
  }
}

.ceil-article-item {
  border-bottom: 1px solid $gray-2;
  padding: 10px;

  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
    font-weight: bold;
    color: var(--green);
    cursor: pointer;
    user-select: none;
    width: 36px;
    height: 36px;
    font-size: 12px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
    flex: 0 0 auto;
  }

  &.ceil-add-item{
    cursor: pointer;

    .add-icon{
      width: 26px;
      height: 26px ;
      margin-bottom: 10px;
    }

    .add-text{
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: $green;
    }
  }

  &.ceil-stay-tuned{
    .add-icon{
      width: 26px;
      height: 26px ;
      margin-bottom: 10px;
    }

    .add-text{
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: $gray-4;
    }
  }

  &.network-item {
    .ceil-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }

    .ceil-description {
      font-size: 13px;
      line-height: 15px;
    }

    .ceil-logo {
      width: 46px;
      height: 46px;
    }
  }

  &.discussion-item {
    padding: 20px 10px;

    .ceil-content {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: space-between;

      .ceil-title,
      .ceil-description {
        overflow: hidden;
      }

      .ceil-explore-link {
        margin-left: 10px;
        flex: 0 0 auto;
      }
    }
  }

  &.group-event-item {
    padding: 15px 10px;
    flex-direction: row;
    display: flex;

    .icon {
      width: 46px;
      height: 46px;
      margin-right: 10px;
      background: $dark-green;
      border: 2px solid $gray-2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      flex: 0 0 auto;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .ceil-content {
      .ceil-event-time {
        a {
          color: $dark-green;
        }
      }
    }
  }

  .ceil-title + .ceil-description {
    margin-top: 6px;
  }

  .ceil-description + .ceil-user-info-wrapper {
    margin-top: 6px;
  }

  .ceil-date{
    margin-top: 6px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: $dark-green;
  }

  .ceil-excerpt{
    margin-top: 12px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
  }

  .ceil-explore-link{
    margin-top: 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    display: flex;
  }

  .ceil-logo {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .actions {
    flex: 0 0 auto;

    .action {
      margin-left: 10px;
      border-radius: 6px;
      background: $gray;
      color: $gray-4;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      padding: 0.5rem;
      flex: 0 0 auto;

      span {
        display: none;
      }

      svg {
        width: 20px;
        height: 20px;

        & + span {
          margin-left: 5px;
        }
      }
    }
  }
}

.ceils-content {
  &-wrapper {
    width: 100%;
    padding: 20px 10px 10px 10px;
    background: $white;


    &.competitions-wrap{
      padding: 0;

      .empty-competitions{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
      }

      .page-pagination {
        margin-bottom: 12px;
      }
    }

    .foot-text{
      padding: 15px 5px;

      &-icon, .icon{
        width: 16px;
        height: 16px;
        margin-right: 6px;
        flex: 0 0 auto;
      }

      .text{
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: $gray-4;
      }
    }

    &.business-block{
      background: transparent;
      min-height: unset;
      border: 0;
      padding: 0;

      .group-categories{
        background: $white;

        .foot-text{
          border: 1px solid $gray-2;
          border-top: 0;
          padding: 15px;
        }
      }

      .business-block{
        &-wrap{
          //background: $white;
          margin-bottom: 20px;
          padding-top: 1px;
          //border: 1px solid $gray-2;

          .ceils-content-section{
            background: $white;
            //border: 1px solid $gray-2;

            .ceil-empty{
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              letter-spacing: 0.01em;
              color: $gray-4;
              border: 1px solid $gray-2;
              width: 100%;
              height: 100%;
              padding: 20px;

              .icon{
                width: 25px;
                margin-bottom: 10px;
              }
            }
          }

          .competition-data{
            padding: 24px 20px;
            letter-spacing: 0.01em;
            background: $white;
            position: relative;

            &-edit-btn{
              position: absolute;
              width: 15px;
              height: 15px;
              top: 12px;
              right: 12px;
            }

            &-title{
              font-weight: bold;
              font-size: 18px;
              line-height: 21px;
              text-align: center;
              color: $black;
              margin-bottom: .875rem;
            }

            &-status{
              font-size: 13px;
              line-height: 15px;
              text-align: center;
              color: $gray-4;
              margin-bottom: .33rem;
            }

            &-date{
              font-size: 13px;
              line-height: 15px;
              text-align: center;
              color: $dark-green;
            }

            &-description{
              font-size: 13px;
              line-height: 15px;
              color: $black;
              margin-bottom: 10px;

              .read-more, .hide-more{
                color: $green;
                font-weight: bold;
                cursor: pointer;
                margin-left: .25rem;
              }
            }

            &-creator{
              margin: 1rem auto;
              justify-content: center;

              &-avatar{
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 50%;
              }

              &-status{
                font-size: 11px;
                line-height: 13px;
                color: $gray-4;
                margin-bottom: .25rem;
              }

              &-name{
                font-size: 15px;
                line-height: 18px;
                color: $black;
              }
            }

            &-actions{
              margin: 0 -10px;
            }

            &-action{
              background: $dark-green;
              border-radius: 6px;
              min-width: 120px;
              height: 42px;
              padding: 8px 18px;
              font-size: 16px;
              line-height: 19px;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              color: $white;
              margin: 10px;
            }

            &-action{
              &.alt{
                background: $green;
              }
            }
          }

          app-competition-participants, app-competition-requests{
            display: block;
            background: $white;
            min-height: 131px;
          }

          .no-participants{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
          }

          .no-reviews{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
          }
        }

        &-header{
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.01em;
          padding: 14px 20px;

          .filter{
            span{
              font-size: 13px;
              line-height: 15px;
              letter-spacing: 0.01em;
              color: $gray-4;
              font-weight: normal;
            }

            .select-wrap{
              margin-left: .5rem;
              position: relative;
              height: 40px;
              background: $light;
              border: 1px solid $gray-2;
              box-sizing: border-box;
              border-radius: 6px;
              overflow: hidden;

              &:after{
                content: "";
                background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229%22%20height%3D%226%22%20fill%3D%22none%22%20viewBox%3D%220%200%209%206%22%3E%3Cpath%20fill%3D%22%2347979D%22%20d%3D%22M7.93%200L4.5%203.7%201.06%200%200%201.14%204.5%206%209%201.14%207.93%200z%22%2F%3E%3C%2Fsvg%3E);
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                width: 9px;
                height: 6px;
                pointer-events: none;
              }

              select{
                appearance: none;
                border: 0;
                padding: 12px 40px 12px 12px;
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.01em;
                color: $black;
              }
            }
          }
        }

        &-item{
          flex-direction: row;
          padding: 20px;
          height: auto;
          border-radius: 0;
          display: flex;
          margin: 0;
          border: 0;
          border-top: 1px solid $gray-2;
          position: relative;

          .options{
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;

            svg{
              width: 15px;
              margin-left: 8px;
            }
          }

          &:last-of-type{
            border-bottom: 1px solid $gray-2;
          }

          &-admin-action{
            // width: 10px;
            height: 10px;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 8pt;
            color: $gray-4;
          }

          &.submission-item{
            .ceil-date{
              color: $gray-4;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.01em;
              text-align: right;
              margin-top: 0;
              margin-left: 10px;
            }

            .ceil-description{
              color: $gray-4;
            }

            .ceil-sub-profile{
              margin: 10px 0;

              &-avatar{
                width: 28px;
                height: 28px;
                margin-right: 10px;
              }

              &-name{
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.01em;
              }
            }

            .ceil-file{
              font-size: 13px;
              line-height: 15px;
              letter-spacing: 0.01em;
              color: $black;
              margin-bottom: 10px;

              &-icon{
                width: 11px;
                margin-right: 10px;
              }
            }
          }
        }

        &-more{
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 0.01em;
          color: $green;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          background: $white;
          border: 1px solid $gray-2;
          margin-top: -1px;
        }
      }
    }

    .search-see-more-btn {
      background: $green;
      border-radius: 6px;
      width: 162px;
      height: 42px;
      display: block;
      margin: 15px auto 20px;
      color: $white;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.01em;
      outline: none;
    }

    .business-block-item{
      display: flex;
      flex-direction: row;
      height: auto;
      min-height: 115px;

      .icon{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        flex: 0 0 auto;
        margin-right: 20px;
      }

      .ceil-tag{
        background: $green;
        border-radius: 20px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        display: inline-block;
        letter-spacing: 0.01em;
        color: $white;
        margin-bottom: 8px;
        padding: 4px 8px;
      }

      .ceil-title{
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.01em;
      }

      .ceil-category{
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: $dark-green;
        margin: 2px 0 8px;
      }

      .ceil-sub-title{
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: $gray-4;
        margin-top: 4px;
      }

      .ceil-description{
        margin: 10px 0;
        color: $black;
      }

      .ceil-actions{
        a{
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.01em;
          margin-right: 30px;

          &:last-of-type{
            margin-right: 0;
          }
        }
      }
    }
  }

  &-header {
    &-title {
      .icon {
        width: 46px;
        height: 46px;
        margin-right: 16px;
      }

      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: $black;

        span {
          font-size: 15px;
          line-height: 18px;
          color: $gray-4;
        }
      }
    }

    .job-search {
      width: 100%;
      margin-top: 10px;
    }
  }

  &-section {
    border-top: 1px solid $gray-2;
    margin-top: 16px;

    .ceil {
      &-title {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: $black;
        margin: 0;

        a {
          color: inherit;
        }
      }

      &-description {
        font-size: 13px;
        line-height: 15px;
        color: $gray-4;
        margin: 0 0 auto;
      }

      &-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        overflow: hidden;
      }

      &-explore-link {
        a {
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: $green;
          cursor: pointer;
        }
      }

      &-verified {
        margin-top: 4px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: $green;

        svg {
          width: 14px;
          margin-left: 4px;
        }
      }

      &-positions {
        margin-top: 4px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: $gray-4;
      }
    }

    .mat-chip-list {
      margin: 0.5rem 0;
      display: block;

      .mat-chip {
        background: $gray-2;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.01em;
      }
    }
  }
}

.ceil-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $gray-2;
  padding: 10px;

  .ceil-logo-wrapper {
    display: flex;
    justify-content: center;
    position: relative;

    .ava,
    .ceil-logo {
      width: 70px;
      height: 70px;
      font-size: 18px;
      border: 2px solid var(--gray-2);
      --background: #eeeeee;
      border-radius: 50%;

      .placeholder {
        width: 100%;
      }

      img,
      app-profile-link {
        min-height: 100%;
        min-width: 100%;
        height: 100%;
        width: 100%;
      }
    }
  }

  .ceil-content {
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ceil-text {
    overflow: hidden;
  }

  .ceil-description {
    margin: 2px 0 0 0;
  }

  .ceil-explore-link {
    margin-top: 5px;
  }

  &.job{
    .ceil-subtitle{
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.01em;
      color: $black;
      margin-bottom: 6px;
    }

    .ceil-title{
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      color: $green;
    }
  }
}

.page-pagination {
  height: 44px;
  border: 1px solid $gray;
  border-radius: 3px;
  display: flex;
  margin: 10px 10px 10px 0;

  &-wrapper{
    background: $white;
  }

  .page-pagination-item {
    display: flex;
    height: 100%;
  }

  .left-button,
  .right-button {
    width: 52px;
    background: $light;
    justify-content: center;
    align-items: center;
    border: 0;

    .arrow {
      width: 24px;
      height: 24px;
      display: block;
      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: $green;
      }
    }
  }

  input[type="text"] {
    border: none;
    text-align: center;
    width: 54px;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    color: $green;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }

  .page-pagination-number {
    width: 53px;
    background: $gray;
    font-size: 13px;
    line-height: 18px;
    color: $gray-7;
    justify-content: center;
    align-items: center;
  }

  .right-button {
    .arrow {
      transform: rotate(180deg);
    }
  }
}

.filter{
  background: $white;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 14px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50px;

  &-wrap{
    position: relative;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: $black;

    &.opened{
      .filter{
        border-radius: 6px 6px 0 0;

        &-data{
          display: block;
        }
      }
    }
  }

  &-label{
    color: #888888;
    padding-right: 8px;
    flex: 0 0 auto;
  }

  &-value{
    color: $black;
    text-transform: lowercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    padding-right: 8px;
  }

  &-data{
    display: none;
    position: absolute;
    width: 100%;
    background: $white;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    box-shadow: 0 4px 10px 4px rgba(192, 192, 192, 0.25);
    border-radius: 0 0 6px 6px;
    padding: 12px 20px;

    &-item{
      padding: 2px 0;
      margin: 6px 0;
      cursor: pointer;

      &.selected{
        color: $green;
      }
    }
  }

  .icon{
    width: 12px;
    height: 7px;
    margin-left: auto;
    flex: 0 0 auto;
  }
}
